import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDebounce } from "hooks/useDebounce";
import { useFetchInstitutions } from "hooks/useQuery/institutions/useFetchInstitutions";
import { Loader } from "components/Loader";

export const RegisterPsychInstitutionSelect = ({ regionId, onSelectInstitution }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [input, setInput] = useState("");
    const debouncedText = useDebounce(input, 500);
    const [filteredList, setFilteredList] = useState([]);
    const { institutions, institutionsIsLoading, institutionsError } = useFetchInstitutions(regionId);

    useEffect(() => {
        if (!regionId) {
            history.push("/psych/register");
        }
    }, [regionId, history]);

    useEffect(() => {
        if (debouncedText.trim() !== "" && institutions) {
            const regex = new RegExp(debouncedText, "i");
            const list = institutions.filter(
                ({ name, institutionID }) => regex.test(name) || regex.test(institutionID)
            );

            setFilteredList(list);
        } else {
            setFilteredList(institutions || []);
        }
    }, [debouncedText, institutions]);

    const changeInputHandler = (e) => {
        setInput(e.target.value);
    };

    return (
        <>
            <div className="auth-page__register-header">
                <h3>{t("choose_institution")}</h3>
            </div>

            {institutionsIsLoading && <Loader />}
            {institutionsError && <p className="data-error">{institutionsError?.message}</p>}

            {institutions && (
                <div className="region-content">
                    <div className="region-find">
                        <input
                            type="text"
                            value={input}
                            onChange={changeInputHandler}
                            placeholder="Название или ID"
                            autoFocus
                        />
                    </div>
                    <div>
                        {filteredList.map(({ _id, name, institutionID }) => (
                            <button
                                className="region-select-btn"
                                onClick={() => onSelectInstitution(name, institutionID)}
                                key={_id}
                            >
                                {name} (ID:{institutionID})
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {!institutionsIsLoading && !institutionsError && (
                <button
                    onClick={() => onSelectInstitution(null, null)}
                    className="main-btn auth-page__btn"
                    style={{ marginTop: 20 }}
                >
                    Я не нашёл своё учреждение в списке
                </button>
            )}
        </>
    );
};
