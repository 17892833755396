import {
    GET_STATISTIC_CONDITIONS,
    GET_STATISTIC_CONDITION_ID,
    GET_STATISTIC_SCHOOL_ID,
    GET_STATISTIC_CLASS_ID,
    GET_STATISTIC_PUPILS,
    SET_STATISTIC_FILTERS,
    SET_STATISTIC_FILTER_CATEGORY,
} from "./types";
import { finishLoading, showAlert, startLoading } from "redux/actions/setAppActions";
import fetch from "redux/fetch";

const initialState = {
    pupilsData: [],
    condtitions: [],
    conditionID: null,
    schoolId: null,
    classId: null,

    filters: {
        sex: "",
        years: "",
        category: "",
        categoryName: "",
        condition: "",
        date: "",
        level: 0,
        scale: "",
    },
};

export const statisticReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATISTIC_PUPILS:
            return { ...state, pupilsData: action.payload };
        case GET_STATISTIC_CONDITIONS:
            return { ...state, condtitions: action.payload };
        case GET_STATISTIC_CONDITION_ID:
            return { ...state, conditionId: action.payload };
        case GET_STATISTIC_SCHOOL_ID:
            return { ...state, schoolId: action.payload };
        case GET_STATISTIC_CLASS_ID:
            return { ...state, classId: action.payload };
        case SET_STATISTIC_FILTERS:
            const { name, value } = action.payload;
            return { ...state, filters: { ...state.filters, [name]: value } };
        case SET_STATISTIC_FILTER_CATEGORY:
            const { category, categoryName } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    category,
                    categoryName,
                },
            };
        case "STATISTIC/SET_FILTERS":
            return { ...state, filters: action.payload };
        default:
            return state;
    }
};

export function getPupils(classId) {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const result = await fetch(`/api/classes/${classId}/pupils`, { method: "GET" });

            dispatch({ type: GET_STATISTIC_PUPILS, payload: result });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}
