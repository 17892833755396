import moment from "moment";

import { validateEmail, validatePhone } from "helpers/validators";

export const psychRegisterFormsValidators = (form, isPhoneConfirmed, isInstitutionNotFound) => {
    const errorFields = [];
    const forbiddenSymbols = /[%;="*?#&()_~]/gi;
    const forbiddenSymbolsInstitution = /[%;=*?#&()_~]/gi;

    if (form.surname.length < 2 || form.surname.length > 20 || forbiddenSymbols.test(form.surname))
        errorFields.push("surname");
    if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name)) errorFields.push("name");
    if (!moment(form.birthday, "DD.MM.YYYY", true).isBetween(-1577934000000, moment.now())) {
        errorFields.push("birthday");
    }
    if (!form.login) errorFields.push("login");
    if (!validateEmail(form.email)) errorFields.push("email");
    if (!validatePhone(form.phone)) errorFields.push("phone");
    if (!form.region) errorFields.push("region");
    if (!isInstitutionNotFound && (!form.institution || forbiddenSymbolsInstitution.test(form.institution)))
        errorFields.push("institution");
    if (!isPhoneConfirmed) errorFields.push("phoneVerification");

    return errorFields;
};

export const psychRegisterFormsValidatorsYanao = (form, isPhoneConfirmed) => {
    const errorFields = [];
    const forbiddenSymbols = /[%;="*?#&()_~]/gi;
    const forbiddenSymbolsInstitution = /[%;=*?#&()_~]/gi;

    if (form.surname.length < 2 || form.surname.length > 20 || forbiddenSymbols.test(form.surname))
        errorFields.push("surname");
    if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name)) errorFields.push("name");
    if (!moment(form.birthday, "DD.MM.YYYY", true).isBetween(-1577934000000, moment.now())) {
        errorFields.push("birthday");
    }
    if (!form.login) errorFields.push("login");
    if (!validateEmail(form.email)) errorFields.push("email");
    if (!validatePhone(form.phone)) errorFields.push("phone");
    if (!form.region) errorFields.push("region");
    if (!form.institution || forbiddenSymbolsInstitution.test(form.institution)) errorFields.push("institution");
    if (!form.institutionID) errorFields.push("institutionID");
    if (!isPhoneConfirmed) errorFields.push("phoneVerification");

    return errorFields;
};
