import { useQuery } from "@tanstack/react-query";

import { fetchInstitutions } from "api/institutions/fetchInstitutions";

/**
 * @param {string} regionId
 */
export function useFetchInstitutions(regionId) {
    const { data, error, isLoading } = useQuery({
        queryKey: ["institutions", regionId],
        queryFn: () => fetchInstitutions(regionId),
        enabled: !!regionId,
    });

    return {
        institutions: data,
        institutionsError: error,
        institutionsIsLoading: isLoading,
    };
}
