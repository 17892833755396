import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { hideNotice, showAlert, showNotice } from "redux/actions/setAppActions";
import { API_URL } from "config";
import { Alert } from "components/Alert";
import { Notice } from "components/Notice";

/**
 * @api {get} /psych/lose восстановления пароля психолога
 * @apiGroup psychologists
 * @apiName восстановления пароля психолога
 *
 * @apiDescription <p>Страница восстановления пароля психолога</p>
 * <p>Компонент <code>LosePassPage.js</code> <a href="../client/src/pages/LosePassPage.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /pupil/lose восстановления пароля ученика
 * @apiGroup pupils
 * @apiName восстановления пароля ученика
 *
 * @apiDescription <p>Страница восстановления пароля ученика</p>
 * <p>Компонент <code>LosePassPage.js</code> <a href="../client/src/pages/LosePassPage.js">ссылка на файл</a></p>
 */

export const LosePassPage = ({ role }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("users");
    const [loading, setLoading] = useState(false);
    const { alert, notice } = useSelector((state) => state.app);
    const { user } = useSelector((state) => state.auth);
    const [form, setForm] = useState({ email: "" });

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };
    useEffect(() => {
        if (user) {
            setForm({ ...form, email: user.email });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const sendPassHandler = async () => {
        setLoading(true);

        try {
            const res = await fetch(`${API_URL}${role}/lose/password`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(form),
            });
            const data = await res.json();

            if (!res.ok) {
                dispatch(showAlert({ type: "error", text: data.message || "Что-то пошло не так" }));
                return;
            }

            dispatch(showNotice(data.message));
        } catch (error) {
            dispatch(showAlert({ type: "error", text: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const hideNoticeHandler = () => {
        dispatch(hideNotice());
    };

    if (role === "pupil") {
        return (
            <div className="main">
                {!!alert && <Alert text={alert.text} type={alert.type} />}
                {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

                <div className="page auth-page">
                    <header className="page__header">
                        <p className="page__title">{t("restore_password")}</p>
                    </header>
                    <div className="auth-page__wrapper">
                        <div className="auth-page__header">
                            <h3>{t("forgot_password")}</h3>
                        </div>
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    value={form.email}
                                    onChange={changeHandler}
                                    placeholder={t("email_placeholder")}
                                />
                                <label htmlFor="email">{t("email")}</label>
                            </div>
                            {role === "psych" ? (
                                <Link to={"/settings/security"}>{t("got_password")}</Link>
                            ) : (
                                <Link to={`/${role}/login`}>{t("got_password")}</Link>
                            )}
                        </div>
                        <button disabled={loading} onClick={sendPassHandler} className="main-btn auth-page__btn">
                            {t("send_password_button")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">{t("restore_password")}</p>
                </header>
                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>{t("forgot_password")}</h3>
                    </div>
                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="email"
                                type="text"
                                name="email"
                                value={form.email}
                                placeholder={t("email_placeholder")}
                                onChange={changeHandler}
                            />
                            <label htmlFor="email">{t("email")}</label>
                        </div>
                        <Link to={`/${role}/login`}>{t("got_password")}</Link>
                    </div>
                    <button disabled={loading} onClick={sendPassHandler} className="main-btn auth-page__btn">
                        {t("send_password_button")}
                    </button>
                </div>
            </div>
        </div>
    );
};
