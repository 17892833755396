import axios from "axios";

export async function fetchRegions() {
    try {
        const response = await axios.get("regions");

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || "Ошибка загрузки данных";

        // eslint-disable-next-line no-throw-literal
        throw { message };
    }
}
