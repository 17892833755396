import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Layout } from "components/Layout";

/**
 * @api {get} /advices/schools/:id/classes список классов для рекомендации
 * @apiGroup recommendations
 * @apiName список классов для рекомендации
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница со списком классов для рекомендации</p>
 * <p>Компонент <code>RecommendationsClasses.js</code> <a href="../client/src/pages/advices/AdvicesClasses.js">ссылка на файл</a></p>
 */

export const AdvicesClasses = () => {
    const { t } = useTranslation("classes");
    const history = useHistory();
    const schoolId = useParams().id;
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`schools/${schoolId}/classes`);
                setClasses(data);
            })();
        }
    }, [schoolId]);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => history.push("/advices/schools")}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{classes[0]?.school.name}</p>
                </header>

                <div className="school-page__wrapper">
                    <div className="list">
                        {classes.map((item) => (
                            <Link key={item._id} to={`/advices/classes/${item._id}/pupils`} className="list__item">
                                <p>
                                    {item.number} {item.letter}
                                </p>
                                <p className="list__desc">
                                    {t("pupils_number")}: {item.pupils.length}, {t("pupils_max")}: {item.max}
                                </p>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
