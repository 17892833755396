import axios from "axios";

/**
 * @param {string} regionId
 */
export async function fetchInstitutions(regionId) {
    try {
        const response = await axios.get(`institutions/${regionId}`);

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || "Ошибка загрузки данных";

        // eslint-disable-next-line no-throw-literal
        throw { message };
    }
}
