import { Input } from 'components/forms/Input';
import './modifications.css'
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'redux/actions/setUserActions';
import { useState } from 'react';

export const NoInstitutionModalYanao = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [institutionID, setInstitutionID] = useState('');

    const updateInstitutionID = () => {
        if (institutionID) {
            const newUser = { ...user, institutionID };
            if(!newUser.identifier) {
                newUser.identifier = institutionID;
            }
            dispatch(updateUser(newUser))
        }
    }

    return (
        <div className="no-institution-modal">
            <div className="no-institution-modal__inner">
                <p className="no-institution-modal__text">
                    Чтобы продолжить, введите идентификатор организации. Уточните свой идентификатор у муниципального координатора
                </p>
                <Input name="institutionID" value={institutionID} onChange={(e) => setInstitutionID(e.target.value)} placeholder="Идентификатор организации"/>
                <button onClick={updateInstitutionID} className='no-institution-modal__btn'>Продолжить</button>
            </div>
        </div> 
    );
};