import { useQuery } from "@tanstack/react-query";

import { fetchRegions } from "api/regions/fetchRegions";

export function useFetchRegions() {
    const { data, error, isLoading } = useQuery({
        queryKey: ["regions/all"],
        queryFn: fetchRegions,
    });

    return {
        regions: data,
        regionsError: error,
        regionsIsLoading: isLoading,
    };
}
